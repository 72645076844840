import config from '../config';

import { buildUrl } from 'utils/string';

export const DEPARTMENT_URL = buildUrl(config.coreBaseURI, config.endpoints.departments);

export const DESIGNATION_URL = buildUrl(config.coreBaseURI, config.endpoints.designations);

export const DESIGNATION_URL_ID = buildUrl(config.coreBaseURI, config.endpoints.designation);

export const AREA_URL = buildUrl(config.coreBaseURI, config.endpoints.areas);

export const REMINDER_LIST_URL = buildUrl(config.coreBaseURI, config.endpoints.remindersList);

export const REMINDER_URL_ID = buildUrl(config.coreBaseURI, config.endpoints.reminder);

export const REMINDER_URL = buildUrl(config.coreBaseURI, config.endpoints.reminders);

export const SKILL_URL = buildUrl(config.coreBaseURI, config.endpoints.skillsV2);

export const SKILL_URL_V1 = buildUrl(config.coreBaseURI, config.endpoints.skills);

export const SKILL_URL_ID = buildUrl(config.coreBaseURI, config.endpoints.skillV2);

export const SKILL_V2_CATEGORY_URL = buildUrl(config.coreBaseURI, config.endpoints.skillV2Category);

export const GLOBAL_RATINGS = buildUrl(config.coreBaseURI, config.endpoints.skillsV2globalratings);

export const SKILL_ABSOLUTE_URL = buildUrl(config.coreBaseURI, config.endpoints.skill);

export const USER_SKILLSV2_URL = buildUrl(config.coreBaseURI, config.endpoints.userSkillsV2);

export const USER_SKILLSV2_STATUS_URL = buildUrl(config.coreBaseURI, config.endpoints.userSkillsV2Status);

export const SKILL_CATEGORY_URL = buildUrl(config.coreBaseURI, config.endpoints.skillCategories);

export const SKILL_CATEGORY_ABSOLUTE_URL = buildUrl(config.coreBaseURI, config.endpoints.skillCategory);

export const ENGAGEMENT_STATUS_URL = buildUrl(config.coreBaseURI, config.endpoints.engagementStatus);

export const USER_URL = buildUrl(config.coreBaseURI, config.endpoints.users);

export const USER_ROLE_URL = buildUrl(config.coreBaseURI, config.endpoints.userRoles);

export const USER_ADD_ROLE_URL = buildUrl(config.coreBaseURI, config.endpoints.userRoles);

export const USER_RESOURCE_URL = buildUrl(config.coreBaseURI, config.endpoints.resource);

export const PEOPLE_FINDER = buildUrl(config.coreBaseURI, config.endpoints.peopleFinder);

export const PEOPLE_FINDER_EXPORT = buildUrl(config.coreBaseURI, config.endpoints.peopleFinderExport);

export const USER_RESOURCE_EXPORT_URL = buildUrl(config.coreBaseURI, config.endpoints.resourceExport);

export const USER_PROFILE_URL = buildUrl(config.coreBaseURI, config.endpoints.userProfile);

export const USER_PROFILE_LIST_URL = buildUrl(config.vyagutaURI, config.endpoints.lms.userProfileList);

export const USER_SKILLS_URL = buildUrl(config.coreBaseURI, config.endpoints.userSkills);

export const USER_TEAM_URL = buildUrl(config.coreBaseURI, config.endpoints.userTeam);

export const USER_IMAGE_UPLOAD_URL = buildUrl(config.coreBaseURI, config.endpoints.userImageUpload);

export const USER_RESIGNATIONS_URL = buildUrl(config.coreBaseURI, config.endpoints.userResignations);
export const USER_RESIGNATION_URL = buildUrl(config.coreBaseURI, config.endpoints.userResignation);

export const USER_LEAVE_ISSUER_UPDATE_NOTIFY = buildUrl(
  config.coreBaseURI,
  config.endpoints.updateUserAndNotifyLeaveIssuer
);

export const USER_TEAM_MANAGER_UPDATE_NOTIFY = buildUrl(
  config.coreBaseURI,
  config.endpoints.updateUserAndNotifyTeamManager
);

export const USER_TEAM_SUMMARY_URL = buildUrl(config.coreBaseURI, config.endpoints.teamSummary);

export const USER_DESIGNATION_UPDATE = buildUrl(config.coreBaseURI, config.endpoints.updateUserDesignation);

export const USER_ENGAGEMENT_UPDATE = buildUrl(config.coreBaseURI, config.endpoints.updateUserEngagementStatus);

export const USER_LEAVE_ISSUER_HISTORY = buildUrl(config.coreBaseURI, config.endpoints.userLeaveIssuerHistory);

export const USER_TEAM_MANAGER_HISTORY = buildUrl(config.coreBaseURI, config.endpoints.userTeamManagerHistory);

export const USER_ACTIVATION_URL = buildUrl(config.coreBaseURI, config.endpoints.userActivate);

export const REFRESH_TOKEN_URL = buildUrl(config.authURI, config.endpoints.auth.token);

export const LOGIN_URL = buildUrl(config.authURI, config.endpoints.auth.login);

export const LOG_OUT_URL = buildUrl(config.endpoints.auth.logout);

export const CALCULATE_LEAVE_FOR_ENGAGEMENT_TRANSITION = buildUrl(
  config.attendanceBaseURI,
  config.endpoints.leaveCalculate
);
export const LEAVE_CREDITS = buildUrl(config.attendanceBaseURI, config.endpoints.leaveCredits);
export const WELL_BEING_LEAVE_CREDITS = buildUrl(config.attendanceBaseURI, config.endpoints.wellBeingLeaves);
export const LEAVES = buildUrl(config.attendanceBaseURI, config.endpoints.attendance.api.leaves);

export const GET_LEAVE_BALANCE_URL = buildUrl(config.attendanceBaseURI, config.endpoints.leaveBalance);
export const APPLY_LEAVE_URL = buildUrl(config.vyagutaURI, config.vyaguta.attendance, config.endpoints.leaveApply);

export const USER_PROJECT_ALLOCATIONS = buildUrl(config.teamsBaseURI, config.endpoints.teams.allocations);

export const PROJECT_DETAILS_URL = buildUrl(config.teamsAppURL, config.endpoints.teams.app.project);

export const PROJECT_OFFBOARD_URL = buildUrl(config.teamsAppURL, config.endpoints.teams.app.offboards);

export const DASHBOARD_QUICK_LINKS = buildUrl(config.coreBaseURI, config.endpoints.quickLinks.quickLinks);
export const QUICK_LINKS_CATEGORIES = buildUrl(config.coreBaseURI, config.endpoints.quickLinks.quickLinksCategories);
export const QUICK_LINKS_CATEGORY = buildUrl(config.coreBaseURI, config.endpoints.quickLinks.quickLinksCategory);
export const QUICK_LINKS = buildUrl(config.coreBaseURI, config.endpoints.quickLinks.quickLinksCategoryLinks);
export const QUICK_LINK = buildUrl(config.coreBaseURI, config.endpoints.quickLinks.quickLinksCategoryLink);
export const QUICK_LINKS_WEIGHTS = buildUrl(config.coreBaseURI, config.endpoints.quickLinks.weights);

export const ROLE_URL = buildUrl(config.coreBaseURI, config.endpoints.roles);
export const ROLE_PERMISSION_URL = buildUrl(config.coreBaseURI, config.endpoints.rolesPermissions);
export const GRANT_ROLE_PERMISSION_URL = buildUrl(config.coreBaseURI, config.endpoints.grantRolesPermissions);
export const REVOKE_ROLE_PERMISSION_URL = buildUrl(config.coreBaseURI, config.endpoints.revokeRolesPermissions);
export const USER_PERMISSIONS = buildUrl(config.coreBaseURI, config.endpoints.userPermissions);
export const PERMISSION_URL = buildUrl(config.coreBaseURI, config.endpoints.permissions);
export const INFO_URL = buildUrl(config.coreBaseURI, config.endpoints.information);

export const NOTES = buildUrl(config.coreBaseURI, config.endpoints.notes);
export const NOTES_URL_ID = buildUrl(config.coreBaseURI, config.endpoints.note);
export const EVENTS = buildUrl(config.coreBaseURI, config.endpoints.events);
export const EVENT_URL_ID = buildUrl(config.coreBaseURI, config.endpoints.event);

export const NOTICES = buildUrl(config.coreBaseURI, config.endpoints.notices);
export const NOTICES_LIST = buildUrl(config.coreBaseURI, config.endpoints.noticesList);
export const NOTICE_URL_ID = buildUrl(config.coreBaseURI, config.endpoints.notice);
export const NOTICE_BOARD = buildUrl(config.coreBaseURI, config.endpoints.noticeBoard);

export const EMPLOYEE_ASSETS = buildUrl(config.coreBaseURI, config.endpoints.empAssets);

export const OCCASIONS = buildUrl(config.coreBaseURI, config.endpoints.occasion);

export const HOLIDAYS = buildUrl(config.coreBaseURI, config.endpoints.holidays);
export const HOLIDAY_URL_ID = buildUrl(config.coreBaseURI, config.endpoints.holiday);

export const SUMMARY_URL = buildUrl(config.attendanceBaseURI, config.endpoints.attendance.api.summary);
export const SELF_WORKLOG_URL = buildUrl(
  config.vyagutaURI,
  config.vyaguta.attendance,
  config.endpoints.attendance.app.selfWorklog
);
export const TEAM_WORKLOG_URL = buildUrl(
  config.vyagutaURI,
  config.vyaguta.attendance,
  config.endpoints.attendance.app.teamWorklog
);
export const SELF_MISSING_URL = buildUrl(
  config.vyagutaURI,
  config.vyaguta.attendance,
  config.endpoints.attendance.app.selfMissing
);
export const TEAM_LEAVE_URL = buildUrl(
  config.vyagutaURI,
  config.vyaguta.attendance,
  config.endpoints.attendance.app.teamLeave
);
export const LEAVE_DETAIL_URL = buildUrl(
  config.vyagutaURI,
  config.vyaguta.attendance,
  config.endpoints.attendance.app.leaveDetail
);
export const LEAVE_ISSUER_CHANGE_REQUEST = buildUrl(config.coreBaseURI, config.endpoints.leaveIssuerChangeRequest);
export const UPDATE_ROLE_URL = buildUrl(config.coreBaseURI, config.endpoints.userRoles);

export const FETCH_INDUSTRIES_URL = buildUrl(config.teamsBaseURI, config.endpoints.teams.industries);

export const LEAVE_ISSUER_CHANGE_REQUESTS_LIST = buildUrl(
  config.coreBaseURI,
  config.endpoints.leaveIssuerChangeRequestsList
);

export const LEAVE_ISSUER_CHANGE_REQUESTS_LIST_EXPORT = buildUrl(
  config.coreBaseURI,
  config.endpoints.leaveIssuerChangeRequestsListExport
);

export const UPDATE_LEAVE_ISSUER_CHANGE_REQUEST = buildUrl(
  config.coreBaseURI,
  config.endpoints.updateLeaveIssuerChangeRequest
);

export const BULK_UPDATE_LEAVE_ISSUER_CHANGE_REQUEST = buildUrl(
  config.coreBaseURI,
  config.endpoints.bulkUpdateLeaveIssuerChangeRequest
);

export const MEDIAN_CLOCKIN_TIME = buildUrl(
  config.attendanceBaseURI,
  config.endpoints.attendance.api.medianClockInTime
);

export const ATTENDANCE_URL = buildUrl(config.vyagutaURI, config.vyaguta.attendance);

export const UNFREEZE_REQUESTS_URL = buildUrl(
  config.attendanceBaseURI,
  config.endpoints.attendance.api.unfreezeRequests
);
