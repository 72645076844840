import React from 'react';
import classNames from 'classnames';
import { FiInfo, FiAlertTriangle } from 'react-icons/fi';

const Info = props => {
  const { className, shouldAlert } = props;

  if (shouldAlert) {
    return (
      <div className={classNames('d-flex mt-1 info__notification info__notification__alert', className)}>
        <div className="info__notification__alert-icon info_notification_alert--icon d-flex">
          <FiAlertTriangle size={18} />
        </div>
        <div className="info__notification__text">{props.message}</div>
      </div>
    );
  }

  return (
    <div className={classNames('d-flex mt-1 info__notification info__notification__normal', className)}>
      <div className="info__notification--icon d-flex">
        <FiInfo size={18} className="rotate-180" />
      </div>
      <div className="info__notification__text">{props.message}</div>
    </div>
  );
};

export default Info;
