function getReleaseNoteObject(date, version, newFeatures, bugFixes) {
  return {
    [version]: {
      date,
      ...(newFeatures && { newFeatures }),
      ...(bugFixes && { bugFixes })
    }
  };
}

function getReleaseNoteV2_20_0() {
  const date = '2022-09-28';
  const version = '2.20.0';
  const newFeatures = {
    'Employee Profile': ['HRs can create a Vyaguta profile for Hired employees.'],
    Holidays: ['HRs can set holidays on a country basis.'],
    Dashboard: ['Holiday will be shown on dashboard as per the location set in your Vyaguta profile.'],
    Footer: ['Added links to report bugs and provide feedback at the end of the page.']
  };

  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_21_0() {
  const date = '2022-11-17';
  const version = '2.21.0';
  const newFeatures = {
    Dashboard: [
      'HRs can create a Vyaguta profile for Hired employees.',
      "The dashboard now allows you to see the weekly amount of hours logged. So, don't forget to log your worklog daily.",
      "The area to see Company's notices has been added in the dashboard. Although we are aware of the hue, but hey that is how you will notice it. 😉"
    ],
    Profile: [
      "We have also added a section inside your profile page to list all the team member's that you are responsible for. So, don't miss to check it out."
    ]
  };

  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_22_0() {
  const date = '2023-01-05';
  const version = '2.22.0';
  const newFeatures = {
    Profile: [
      'Leave Issuers, Team Managers, Project Managers, and Team Leads can now request to change the leave issuer of their team members from their profile.'
    ],
    'Leave Issuer': ['Status updates in leave issuer change requests will be notified via email.']
  };

  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_23_0() {
  const date = '2023-02-02';
  const version = '2.23.0';
  const newFeatures = {
    'List 0': [
      'Easy navigation for People Management team with merged tabs.',
      'The People Management Team can now seamlessly toggle between viewing past and future events & notices to stay organized.',
      'Say goodbye to outdated calendars, the date picker has been given a futuristic upgrade! You can now travel through time with just a few clicks.'
    ]
  };
  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_24_0() {
  const date = '2023-03-01';
  const version = '2.24.0';
  const newFeatures = {
    'List 0': [
      'Booked users can now view their current booking status right on their dashboard. Also, we have email notifications in your inbox for the same.',
      'Account Managers and Relationship Managers can now view their team members leave in the member section of the dashboard.'
    ]
  };

  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_25_0() {
  const date = '2023-03-15';
  const version = '2.25.0';
  const newFeatures = {
    'List 0': ['People Management Team can now add a tentative exit date for exiting Leapfrogger.']
  };

  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_26_0() {
  const date = '2023-04-10';
  const version = '2.26.0';
  const newFeatures = {
    Dashboard: [
      'My Allocations widget now includes your individual logged hours, pending worklogs, and missed attendance.',

      `Access info about your team\'s allocation and relationships with ease in the new "My Team" card.
      More features and functionalities coming soon over the next weeks to improve your experience. Stay tuned!`
    ],
    Pulse: [
      'Exciting news! Our newest feedback-based system is here! Click the pulse icon by hovering over your team member in the "My Team" table or their profile. Let\'s get our pulses racing!'
    ]
  };

  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_26_1() {
  const date = '2023-04-26';
  const version = '2.26.1';
  const newFeatures = {
    Dashboard: [
      `Users can now view their team member's today's attendance, pending/missing work logs, and planned leave from the "My Team" card.`,
      `Applicable users have the option to request for changing leave issuer and appraisers from the "My Team" card.`,
      "Removed the 'Quick Links' section. Don't worry, we plan to bring a revamped layout to view quick links soon."
    ]
  };

  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_26_2() {
  const date = '2023-06-07';
  const version = '2.26.2';
  const newFeatures = {
    Dashboard: [
      'Leapfroggers can now view the count of the people for whom they are responsible as Leave Issuer, Team Manager, Appraiser and Co-appraiser.'
    ],
    'Leave Issuer Change Request': [
      'Leapfroggers can now view an alert message where they can see the number of people for whom the selected leave issuer is responsible for.'
    ]
  };

  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_27_0() {
  const date = '2023-06-27';
  const version = '2.27.0';
  const newFeatures = {
    Skills: [],
    'Paragraph 0': ['We are thrilled to introduce the new version of Skills.'],
    'List 0': [
      'Skills have been categorized into different sub-skills.',
      'We have introduced a skill verification process that involves both Team Manager and Directs.',
      'Team Manager has the ability to flag ratings assigned by Directs and suggest changes to the skill ratings.',
      'Team Manager can recommend the removal of certain skills if necessary.'
    ]
  };

  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_28_0() {
  const date = '2023-07-17';
  const version = '2.28.0';
  const newFeatures = {
    'Profile / My Allocation': [],
    'Paragraph 0': ['As an Appraisee, you can now request for change of your Appraisers with ease!'],
    'List 0': [
      'Navigate to the "My Allocation" area in the Vyaguta Dashboard, hover over your Appraiser(s) profile icon, and click on the "Request for appraisers change" button.',
      'Alternatively, head over to your profile and find the "Request for appraisers change" button conveniently placed next to your allocations in the "Allocation" section.'
    ]
  };

  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_29_0() {
  const date = '2023-08-16';
  const version = '2.29.0';
  const newFeatures = {
    'Exiting leapfroggers': [],
    'List 0': [
      'Exiting leapfroggers will now receive email notifications outlining their roles and responsibilities to be transferred, two weeks prior to their anticipated exit date.'
    ]
  };

  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_30_0() {
  const date = '2024-04-01';
  const version = '2.30.0';
  const newFeatures = {
    'Link Library': [],
    'List 0': [
      'Access all relevant information effortlessly through the organized link library by simply clicking on the link library icon.'
    ]
  };

  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_31_0() {
  const date = '2024-06-06';
  const version = '2.31.0';
  const confulenceLink = `https://lftechnology.atlassian.net/wiki/spaces/LEAP/pages/4277043209/Median+Clock-in+Time+Calculation+Method`;
  const newFeatures = {
    'Introducing Median Clock in Time!': [],
    'List 0': [
      'Your median check-in time can now be viewed in the dashboard widget. This is calculated using your WiFi logs when you arrive at the Kathmandu office.',
      'You can view the last 30 days’ history through the widget.',
      `Your median check-in time is only visible to you.!`
    ],
    'Paragraph 1': [
      'It’s just a friendly reminder to help keep us all on track!',
      `To learn more about these changes, please check out our {{Confluence Link,${confulenceLink}}}, where you'll find comprehensive information.`
    ]
  };
  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_32_0() {
  const date = '2024-08-28';
  const version = '2.32.0';
  const newFeatures = {
    Assets: [],
    'List 0': ['Leapfroggers can now view the list of the assets acquired in their profile page under "Assets" tab.']
  };

  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_32_1() {
  const date = '2024-08-30';
  const version = '2.32.1';
  const newFeatures = {
    'Leave Issuer change': [],
    'List 0': [
      'The current leave issuer cannot be changed unless all pending leave requests and worklogs are approved.'
    ]
  };

  return getReleaseNoteObject(date, version, newFeatures);
}

function getReleaseNoteV2_33_0() {
  const date = '2024-09-25';
  const version = '2.33.0';
  const newFeatures = {
    'Search Leapfrogger': [],
    'List 0': [
      'Introducing a global search feature that lets Leapfroggers quickly find any Leapfrogger across all modules with a convenient shortcut, improving navigation and streamlining access to their information.',
      'Keyboard shortcut for Windows/Linux: Ctrl + /',
      'Keyboard shortcut for macOS: cmd + /'
    ]
  };

  return getReleaseNoteObject(date, version, newFeatures);
}

export const releaseNotes = {
  ...getReleaseNoteV2_33_0(),
  ...getReleaseNoteV2_32_1(),
  ...getReleaseNoteV2_32_0(),
  ...getReleaseNoteV2_31_0(),
  ...getReleaseNoteV2_30_0(),
  ...getReleaseNoteV2_29_0(),
  ...getReleaseNoteV2_28_0(),
  ...getReleaseNoteV2_27_0(),
  ...getReleaseNoteV2_26_2(),
  ...getReleaseNoteV2_26_1(),
  ...getReleaseNoteV2_26_0(),
  ...getReleaseNoteV2_25_0(),
  ...getReleaseNoteV2_24_0(),
  ...getReleaseNoteV2_23_0(),
  ...getReleaseNoteV2_22_0(),
  ...getReleaseNoteV2_21_0(),
  ...getReleaseNoteV2_20_0()
};
