import moment from 'moment';

import { HH_MM_SS, H_MM_A } from 'constants/date';

/**
 * Convert string minute time to display format eg: 124 to 2h 4m to.
 *
 * @param {String} time : in formate 5h 2m.
 *
 * @returns {String} Formatted Time to 5h 1m formate.
 */
export function convertMinuteToDisplayFormat(time) {
  const intTime = parseInt(time);

  const minutes = intTime % 60;
  const hours = Math.floor(intTime / 60);

  return `${hours || 0}h ${minutes || 0}m`;
}

/**
 * Converts a time string in HH:mm:ss format to h:mm a format.
 *
 * @param {string} timeString - The time string in HH:mm:ss format.
 * @returns {string} The formatted time in h:mm a format (e.g., "9:07 a.m.").
 */
export function convertTimeToAMPM(timeString) {
  const parsedTime = moment(timeString, HH_MM_SS);

  const formattedTime = parsedTime.format(H_MM_A);

  return formattedTime;
}

/**
 * Formats the given time into the format "12:00 p.m., 3:45 a.m.".
 * @param {string} time - The time string to format (e.g., "12:00 am").
 * @returns {string} The formatted time string.
 */
export const convertTimeToAMPMWithDots = time => {
  const splitTime = time.split(' ');

  const formattedTime = `${splitTime[0]} ${splitTime[1]
    .split('')
    .map(item => item + '.')
    .join('')}`;

  return formattedTime;
};

/**
 * Fetches the median time from an array of time strings.
 *
 * @param {string[]} times - The array of time strings in HH:mm:ss format.
 * @returns {string | null} - The median time string in HH:mm:ss format, or null if the input array is empty.
 */
export function getMedianTime(times) {
  if (times.length === 0) {
    return null;
  }

  const sortedTimes = times.map(time => moment(time, H_MM_A)).sort((a, b) => a.diff(b, 'seconds'));

  const middleIndex = Math.ceil(sortedTimes.length / 2) - 1;

  return sortedTimes[middleIndex].format(HH_MM_SS);
}
